<template>
    <div v-bind:class="{ 'scrolled' : scrolled }" v-scroll="handleScroll">
        <slot></slot>
    </div>
</template>
#
<script>
import { ref } from '@vue/composition-api'
import Scroll from '../directives/Scroll'

export default {
    name: 'PageContainer',
    directives: { Scroll },
    setup(props, context) {
        
        const scrolled = ref(false)

        function handleScroll() {
            scrolled.value = window.pageYOffset > 10;
        }

        handleScroll();

        return { 
            scrolled,
            handleScroll
        }

    }
}
</script>