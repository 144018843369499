<script>
import Velocity from 'velocity-animate';

export default {
    name: 'BackToTop',
    setup(props, context) {
        return () => context.slots.default({
            linkEvents: {
                click(e) {
                    e.preventDefault();
                    Velocity(document.body, "scroll", { offset: "0", duration: "slow", mobileHA: false });
                }
            }
        }) 
    }
}
</script>