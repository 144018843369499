<template>
    <header :class="{ 'nav-open' : isOpen, 'nav--mobile': isMobile, 'nav--desktop': !isMobile }">
        <media :query="{ minWidth: mobileMinWidth }" @media-enter="isMobile = false" @media-leave="isMobile = true" />
        <slot v-bind:scope-events="scopeEvents" v-bind:scope-props="scopeProps"></slot>
    </header>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import Media from 'vue-media';

export default {
    name: 'SiteHeader',
    components: { Media },
    setup: function (props, context) {

        const mobileMinWidth = 1024; // Tailwind MD
        const isMobile = ref(window.innerWidth < mobileMinWidth);
        const isOpen = ref(false);

        function toggleNav(e) {
            e.preventDefault();
            isOpen.value = !isOpen.value;
        }

        watch(() => {
            if (!isMobile.value) {
                isOpen.value = false;
            }
        })

        return {
            mobileMinWidth,
            isMobile,
            isOpen,
            scopeEvents: {
                toggle: {
                    click: toggleNav
                }
            },
            scopeProps: {
                isOpen,
                isMobile
            }
        }

    }
}
</script>