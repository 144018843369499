<template>
    <form ref="form" v-validate-form>
        <slot name="body" v-bind:scope-events="scopeEvents" v-bind:scope-props="scopeProps"></slot>
        <slot></slot><!-- Default slot to capture ufprt -->
    </form>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'

export default {
    name: 'CheckoutForm',
    setup: function (props, context) {

        const taxCodeRequired = ref(false);

        function countryChange() {
            var el = context.refs.form.querySelector("select[name=country]");
            var elValue = el.value;
            if (elValue) {
                var selectedOption = el.querySelector("option[value=\""+ elValue +"\"]");
                if (selectedOption && selectedOption.hasAttribute("data-tax-country")) {
                    taxCodeRequired.value = true;
                    return;
                }
            }
            taxCodeRequired.value = false;
        }

        onMounted(() => countryChange())

        return {
            scopeEvents: {
                country: {
                    change: countryChange
                }
            },
            scopeProps: {
                taxCodeRequired
            }
        }

    }
}
</script>