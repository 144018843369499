<template>
    <form ref="form">
        <slot></slot>
    </form>
</template>

<script>
import { onMounted } from '@vue/composition-api'

export default {
    name: 'AutoSubmittingForm',
    setup: function (props, context) {

        onMounted(() => {
            setTimeout(function() {
                if (context.refs.form.hasAttribute("onsubmit"))
                    context.refs.form.dispatchEvent(new Event('submit', { cancelable: true }));
                else
                    context.refs.form.submit();
            }, 100)
        })
 
    }
}
</script>