<template>
    <div class="relative">
        <flickity ref="flickity" :options="flickityOptions" @init="flickityReady" class="overflow-hidden mb-10">
            <slot></slot>
        </flickity>
        <div class="absolute bottom-0 -mb-10 h-10 w-full flex items-end" v-if="numberOfSlide > 1">
            <div class="flex items-center justify-between w-full">
                <span>{{ selectedIndex + 1 }} of {{ numberOfSlide }}</span>
                <ul class="hidden sm:flex list-reset flex-1 mx-6 rounded-full">
                    <li v-for="s in numberOfSlide" :key="s" :class="{ 'border-brand-blue-dark' : s-1 == selectedIndex }" class="flex-1 border-t border-b border-gray-400"></li>
                </ul>
                <span class="flex">
                    <button @click="previous()" class="inline-block w-6 h-6 text-brand-blue-dark hover:text-brand-blue-light mr-3"><sprite sprite-id="arrow" class="fill-transparent stroke-current rotate-90 align-middle transform rotate-180 w-full h-full" /></button>
                    <button @click="next()" class="inline-block w-6 h-6 text-brand-blue-dark hover:text-brand-blue-light"><sprite sprite-id="arrow" class="fill-transparent stroke-current rotate-270 align-middle w-full h-full" /></button> 
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Flickity from './_Flickity.vue';
import Sprite from './_Sprite.vue';
import lazysizes from 'lazysizes';

export default {
    name: 'RotatingCarousel',
    components:{
        Flickity,
        Sprite
    },
    data () {
        let self = this;
        return {
            flickityOptions: {
                wrapAround: true,
                adaptiveHeight: true,
                imagesLoaded: true,
                prevNextButtons: false,
                pageDots: false,
            },
            numberOfSlide: 0,
            selectedIndex: 0
        }
    },
    mounted () {
        let self = this;
        let flickityInstance = self.$refs.flickity.flickity();

        let onSelectChange = () => {
            self.selectedIndex = self.$refs.flickity.selectedIndex();
        }
        self.$refs.flickity.on('select', onSelectChange);

        let onChange = () => {
            flickityInstance.cells.forEach(function(cell, i) {
                if (cell.element == flickityInstance.selectedElement) {
                    // play video if active slide contains one
                    // use <video playsinline ..> in your html to assure it works on ios devices
                    var video = cell.element.querySelector("video");
                    if (video) {
                        video.play();
                    }
                    return;
                }

                // stop all other videos
                var video = cell.element.querySelector("video");
                if (video) {
                    video.pause();
                    video.currentTime = 0;
                }
            });
        }
        self.$refs.flickity.on('change', onChange);
        onChange();

        let onLoadeddata = (evt) => {
            setTimeout(() => {
                var cell = flickityInstance.getParentCell(evt.target);
                flickityInstance.cellSizeChange(cell && cell.element);
            }, 10);
        }

        var videos = self.$refs.flickity.$el.getElementsByTagName("video");
        for(var v = 0; v < videos.length; v++){
            videos[v].addEventListener( 'loadeddata', onLoadeddata );
            onLoadeddata({
                target: videos[v]
            });
        }

        self.$once('hook:beforeDestroy', () => {
            self.$refs.flickity.off('select', onSelectChange);
            self.$refs.flickity.off('staticClick', onStaticClick);
        })
    },
    methods: {
        flickityReady() {
            this.numberOfSlide = this.$refs.flickity.slides().length;
            lazysizes.autoSizer.checkElems();
        },
        next() {
            this.$refs.flickity.next();
        },
        previous() {
            this.$refs.flickity.previous();
        },
        select(s) {
            this.$refs.flickity.select(s);
        }
    }
}
</script>