<template>
    <div>
        <slot v-bind:scope-events="scopeEvents" v-bind:scope-props="scopeProps"></slot>
    </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
    name: 'CollapsableItem',
    setup: function (props, context) {

        const isOpen = ref(false);

        function toggle(e) {
            e.preventDefault();
            isOpen.value = !isOpen.value;
        }

        return {
            scopeEvents: {
                toggle: {
                    click: toggle
                }
            },
            scopeProps: {
                isOpen
            }
        }

    }
}
</script>