<template>
    <div>
         <div class="font-medium inline-flex items-center justify-between rounded-full max-w-full border border-white mx-auto mb-8"
            v-if="tabs.length > 1">
            <a v-for="tab in tabs" :key="tab.name"
                :href="tab.href" @click.prevent="selectTab(tab)"
                :class="{ 'bg-white' : tab.isActive, 'text-white' : !tab.isActive }"
                class="inline-flex items-center py-2 px-4 -m-px rounded-full">{{ tab.name }}</a>
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ToggleGroups',
    data() {
        return {
            tabs: [] 
        };
    },
    created() {
        this.tabs = this.$children;
    },
    methods: {
        selectTab(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = (tab.name == selectedTab.name);
            });
        }
    }
}
</script>