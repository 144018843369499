<script>
import { computed } from '@vue/composition-api'

export default {
  name: "EmbeddedJsonProvider",
  props: ['src'],
  setup(props, context) {

    const json = computed(() => {
        
      if (!props.src) 
        return null;
      
      if (typeof props.src === "object") {
        if (props.src.constructor === Array) {
          return props.src.reduce((map, id) => {
            map[id] = getJsonFrom(id);
            return map;
          }, {});
        } else {
          return Object.keys(props.src).reduce((map, key) => {
            map[key] = getJsonFrom(props.src[key]);
            return map;
          }, {});
        }
      } else {
        let map = {};
        map[props.src] = getJsonFrom(props.src);
        return map;
      }

      return {};

    })

    function getJsonFrom(id) {
      const scriptEl = document.getElementById(id);
      if (!scriptEl) 
        return null;
      return JSON.parse(scriptEl.textContent);
    };

    return () => context.slots.default 
      ? context.slots.default(json.value) 
      : null
      
  }
};
</script>
