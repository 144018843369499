import Vue from 'vue'
import PortalVue from 'portal-vue'
import VueCompositionApi from '@vue/composition-api';
import VueScrollTo from 'vue-scrollto';
import VTooltip from 'v-tooltip'
import ValidateForm from './directives/ValidateForm';

import 'lazysizes';

// Pollyfills
require('matchmedia-polyfill');
require('matchmedia-polyfill/matchMedia.addListener');
require('es6-promise').polyfill();
require('element-closest');

// Manually declare any async components (https://vuejs.org/v2/guide/components-dynamic-async.html#Async-Components)
// Async components will be auto loaded and cached when first requested. 
// You should only really do this for larger more complex components or 
// ones that are only used sparingly as loading all components async would 
// could negate the benefits by making too many requests.
// Based on https://nayzawoo.github.io/blog/2018/02/26/Laravel-Mix-Setup-For-Vue-Async-Components/

// Vue.component('MapboxMap', () => import('./components/_MapboxMap.vue'))

// Globally register all components by default as we won't know if / when a component
// will be used as it'll be decided by the CMS. If you don't want it globally registerd
// then prefix the filename with a '_'
// taken from https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
const requireComponent = require.context('./components', true, /(?:^|\/)(?!_)\w+\.vue$/);
// For each matching file name...
requireComponent.keys().forEach(fileName => {
  // Get the component config
  const componentConfig = requireComponent(fileName)
  // Get the default component from the config
  const component = componentConfig.default || componentConfig;
  // Globally register the component using the component name from the component config
  Vue.component(component.name, component)
})

// Global directives
Vue.directive("ValidateForm", ValidateForm)

// Global using statements
Vue.use(VueCompositionApi)
Vue.use(PortalVue)
Vue.use(VTooltip)
Vue.use(VueScrollTo)

// Reactify the page
window.vm = new Vue({
  el: '#page'
})