export default {
    inserted: function (el, binding) {
        let f = function (evt) {
            // If the binding handler function returns true,
            // remove it so it's not called again
            // If you want your handler to continue to run,
            // just don't return true
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', f)
    }
}