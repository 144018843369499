<template>
    <svg role="presentation" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" v-bind:xlink:href="spriteHref"></use></svg>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'Sprite',
  props: {
      spriteId: String
  },
  setup(props) {
    const spriteHref = computed(() => `/images/sprites.svg#${props.spriteId}`)
    return {
      spriteHref
    }
  }
}
</script>