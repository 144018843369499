export default {
    inserted: function (el, binding) {

        const inputs = el.querySelectorAll("input, select, textarea");

        inputs.forEach(input => {
            input.addEventListener(
                "invalid",
                event => {
                    input.classList.add("error-if-invalid");
                },
                false
            );
            input.addEventListener("blur", function() {
                input.checkValidity(); 
            });
        });
    }
}